<template>
  <v-container>
    <v-card outlined width="1000px" class="mx-auto pa-2 pb-0 " elevation="3" >
      <v-layout wrap>
        <v-select dense outlined class="ma-1" style="height: 40px; width: 200px;" 
          id="farm" 
          ref="farm" 
          label="농장" 
          v-model="farm"
          :items="farmItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeFarm()"
          @focus="comboFarms()"
          clearable
        />
        <v-select dense outlined class="ma-1" style="height: 40px; width: 200px;"
          id="building" 
          ref="building" 
          v-model="building"
          :label="building.code ? '축사' : '축사 (선택없으면 농장전체)'" 
          :items="buildingItems"
          :menu-props="{ top: false, offsetY: true }"
          no-data-text="자료(권한)이 없습니다."
          item-text="name"
          return-object
          @change="changeBuilding()"
          @focus="comboBuildings()"
          clearable
        />
      </v-layout>
      <v-layout wrap>
        <v-layout wrap ma-1>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="10"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-2 mt-1" style="height: 40px; width: 165px;"
                id="fromdate" 
                ref="fromdate" 
                v-model="fromdate"
                label="부터"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="fromdate"
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="-120"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field dense outlined clearable class="shrink mr-2  mt-1" style="height: 40px; width: 165px;"
                id="todate" 
                ref="todate" 
                v-model="todate"
                label="까지"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title locale="ko-KR"
              v-model="todate"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-card style="height: auto;">
            <v-radio-group dense outlined row style="margin-top: 5px; margin-bottom: -15px;"
              id="event" 
              ref="event" 
              v-model="event"
            >
              <v-radio class="ml-2 "  
                label="전체" value="EV" color="black" 
                @click="listEvents()">
              ></v-radio>
              <v-radio
                label="입식" value="EVEN"  color="blue" 
                @click="listEvents()">
              ></v-radio>
              <v-radio
                label="이동" value="EVTR" color="cyan" 
                @click="listEvents()">
              ></v-radio>
              <v-radio
                label="출하" value="EVSD" color="green" 
                @click="listEvents()">
              ></v-radio>
              <v-radio
                label="도폐" value="EVDT" color="red" 
                @click="listEvents()">
              ></v-radio>
            </v-radio-group>
          </v-card>
        </v-layout>
        <v-btn rounded-pill class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 110px"
          id="listEvents" 
          :loading="loading" 
          ref="listEvents" 
          @click="listEvents()">
          <img src="@/assets/icon_refresh.svg" alt="새로고침" />
          조회
        </v-btn>
      </v-layout>
    </v-card>

    <v-card width="1000" height="auto"  class="mx-auto ma-2 pa-2  rounded-lg">
      <v-data-table dense height="530"
        fixed-header
        hide-default-footer
        :items-per-page= "500"
        :loading="loading"
        id="items" 
        ref="items" 
        :headers="headers"
        :items="items"
        no-data-text="등록된 자료가 없거나 접근 권한이 없습니다."
        class="elevation-3"
        @click:row="selectRow"
        @dblclick:row="selectedEvent.event_id && detailInfo(selectedEvent)"
        item-key="event_id"
        single-select
        :item-class="itemRowBackground"
        :sort-by.sync="sortkey"
        :sort-desc.sync="istrue"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
            color=#3a4f3f
            large
            @click="detailInfo(item)"
          >
            mdi-pencil-box
          </v-icon>
        </template>
      </v-data-table>
      <v-row justify= "center" class="mx-auto ma-2 pa-2  rounded-lg">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn class="rounded-pill elevation-7 normal-btn"
         @click="selectedEvent.event_id && detailInfo(selectedEvent) || $store.commit('resMessage','대상을 선택후 조회하세요')">
          내용보기
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="rounded-pill elevation-7 new-btn" 
        href="/dailyrecord/event">
          새로등록
        </v-btn>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
 </v-container>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
import VueCookies from "vue-cookies";

export default {
  name: 'ListEvents',

  async created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    // console.log("param",this.$route.params)
    // 등록에서 넘어 왔을경우 검색 경영체,농장 적용
    // if (!this.isNull(this.$route.params)) {
    //   this.company = this.$route.params.company;
    //   this.farm = this.$route.params.farm;
    // }

    await this.comboFarms();
    await this.comboBuildings();

    let cookie_info = VueCookies.get("listEvents_info");
    if (cookie_info) {
      this.farm = cookie_info.farm || {};
      this.building = cookie_info.building || {};
      this.fromdate = cookie_info.frdate;
      this.todate = cookie_info.todate;
      this.event = cookie_info.event;
    } else {
      let cdate = new Date();
      this.todate = dateUtil.format(cdate, "yyyy-MM-dd");
      let frdate = new Date(cdate.setDate(cdate.getDate() - 31));
      this.fromdate = dateUtil.format(frdate, "yyyy-MM-dd");
                      this.farm = this.farmItems[0];
    }
    if (!this.isNull(this.$route.params)) {
      this.routedEvent = this.$route.params.event_id;
    }
    this.listEvents();
  },

  data() {
    return {
      headers: [
        { text: '',align: 'center', sortable: false, value: 'actions', width: "5px", groupable: false, class: 'green darken-4 white--text' },
        { text: '구분', value: 'event', width: "75px", class: 'green darken-4 white--text' },
        { text: '축사', value: 'building', width: "110px", class: 'green darken-4 white--text' },
        { text: '돈방', value: 'content', width: "150px", class: 'green darken-4 white--text' },
        { text: '두수', align: 'end', value: 'pigcnt', width: "120px", class: 'green darken-4 white--text' },
        { text: '날짜', align: 'center', value: 'date', width: "110px", class: 'green darken-4 white--text' },
        { text: '메모', value: 'memo', width: "350px", class: 'green darken-4 white--text' },
        { text: '입력자/입력일', value: 'updater', width: "180px", class: 'green darken-4 white--text' },
        { text: 'ID', value: 'event_id', width: "75px", class: 'green darken-4 white--text' },
      ],
      items : [],
      farm : {},
      farmItems : [{code: "",name: ""}],
      building : {},
      buildingItems : [{code: "",name: ""}],

      fromdate: "",
      todate: "",
      event: "EV",

      loading: false,
      selectedEvent: {},
      selectedRow: {},
      routedEvent: "",

      menu1: false,
      menu2: false,
      sortkey: "event_id",
      istrue: true,

    }
  },
  methods: {
    listEvents() {
        if (!this.farm) return;
        this.$store.commit("resMessage","");
        this.loading = true;
        if (this.selectedRow.item) {this.unselectRow(this.selectedRow)}
        // console.log("리스트 이벤트");
        Apis.listFarmEvent({
            company_cd: this.farm.company_cd,
            farm_cd: this.farm.code,
            building_cd: this.building.code ? this.building.code : "",
            fr_date: this.fromdate,
            to_date: this.todate,
            event_cd: this.event,
          },
          res => 
            {
              this.loading = false;
              if (res.result) {
                // console.log(res.data);
                this.items = res.data;
                this.$store.commit("resMessage",res.message);
                let cookie_info = { 
                                    farm: this.farm,
                                    building: this.building,
                                    frdate: this.fromdate,
                                    todate: this.todate,
                                    event: this.event,
                                  }
                VueCookies.set("listEvents_info" ,cookie_info, 60 * 60 * 9); // 이전조회정보 9시간 저장 : 하루동안 조회정보 재입력 불필요하게 유지
              } else {
                alert(res.message);
                this.$store.commit("resMessage",res.message);
              }
            }          
        ).catch(err => {  // 개별 API 오류 처리 예제
            this.loading = false;
            console.log("listEvents 호출 오류",err);
            this.$router.push("/");
          }) 
    }, 
    selectRow(item,row) {
      row.select(true);
      // console.log("item=",item,"row=,",row)
      this.selectedEvent = item;
      this.selectedRow = row;
      this.routedEvent = "";
    },
    unselectRow(row) {
      row.select(false);
      this.selectedEvent = {};
      // this.routedEvent = "";
    },
    detailInfo(value) {
          // console.log("상세조회",value.building_cd);
          // alert(value.company_name + "의 정보를 상세조회합니다")
          this.$router.push({
            name: "Event",
            params: { event_id: value.event_id },
          });
    }, 
    async comboFarms() {
      await Apis.comboFarms({
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
        }
      ) 
    },
    changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.farm && this.farm.code) {
        this.building = {};
      }
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:"",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building && this.building.farm_cd) {
        this.farm = {company_cd: this.building.company_cd, company_name: this.building.company_name,
                     code: this.building.farm_cd, name: this.building.farm_name};
      }
    },
    itemRowBackground(item) {
      return item.event_id == this.routedEvent ? 'selected' : '' ;
    },

  },  // -----------methods 
} // -----------export 
</script>
<style lang="scss" scoped>
.normal-btn{
  vertical-align: middle !important;
  color:white ;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}
.new-btn{
  color:#3a4f3f !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: rgb(248, 248, 248) !important;
}
.dialog-btn{
  color:white !important;
  font-size:12pt;
  font-weight: bold !important;
  background-color: #88191a !important;
}
</style>
